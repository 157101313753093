nav {
  z-index: 100;
  // position: absolute;
  width: 100vw;
  position: fixed;
  transition: background-color 0.3s ease;
  height: auto;
}

nav.scrolled {
  background-color: white;
}


@keyframes slideIn {
  from {
    right: -250px;
  }
  to {
    right: 0;
  }
}

.nav-container {
  padding: 1.25vw 5.556vw;
  position: relative;
  border-bottom: 1px solid #f2f4f7;
  height: 100%;
  .nav-wrapper {
    padding: 0 3.222vw;
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 100%;
    .hamburger {
      display: none;
    }
    .nav-left-section {
      display: flex;
      gap: 2.778vw;
      .nispandLogo {
        display: flex;
        align-items: center;
      }
      img {
        width: 158px;
        height: 40px;
      }
      .nav-items {
        display: flex;
        gap: 2.222vw;
        cursor: pointer;
        .nav-item {
          color: #667085;
          font-size: 16px;
          font-family: "Inter", sans-serif;
          font-weight: 600;
          line-height: 24px;
          word-wrap: break-word;
          cursor: pointer;
          align-items: center;
          display: flex;
          height: 100%;
        }
        .nav-item-active {
          color: #4b2e85; 
        }
      }
    }
    .nav-right-section {
      gap: 0.833vw;
      display: flex;
      align-items: center;
      .partner-login {
        font-weight: 600;
        border-radius: 8px;
        width: 141px;
        height: 44px;
        padding: 10px 18px;
        border: none;
        background-color: white;
        font-size: 16px;
        color: #667085;
        line-height: 1.667vw;
        font-family: "Inter", sans-serif;
        align-items: center;
        display: flex;
        // :visited{
          text-decoration: none;
        // }
      }
      .download-btn {
        font-weight: 600;
        border-radius: 8px;
        width: 182px;
        height: 44px;
        padding: 10px 18px;
        border: none;
        background-color: #1c3879;
        font-size: 16px;
        color: white;
        line-height: 1.667vw;
        font-family: "Inter", sans-serif;
        align-items: center;
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  // nav {
  //   height: auto;
  // }
}

@media screen and (max-width: 1000px) {
  .nav-container {
    padding: 1.25vw 2.556vw;
    .nav-wrapper {
      .nav-right-section {
        .download-btn {
          width: 135px;
          height: 30px;
          padding: 10px 18px;
          font-size: 11px;
        }
        .partner-login {
          width: 95px;
          height: 30px;
          padding: 10px 11px;
          font-size: 11px;
        }
      }
      .nav-left-section {
        img {
          width: 10.972vw;
          height: 2.778vw;
        }
        .nav-items .nav-item {
          color: #667085;
          font-size: 11px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  nav {
    height: 72px;
    .nav-container {
      padding: 0px 16px;
      .nav-wrapper {
        padding: 0 0;
        .hamburger {
          display: flex;
          width: 10.667vw;
          height: 10.667vw;
        }
        .nav-left-section {
          img {
            width: 42.293vw;
            height: 10.667vw;
          }
          .nav-items {
            display: none;
          }
        }
        .nav-right-section {
          display: none;
        }
      }
    }
  }
}

.sidenav {
  padding: 6.4vw 0vw;
  background: #ffffff;
  height: 100%;
  width: 100%;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  text-align: center;
  top: 0;
  z-index: 100000;
  transition: left 0.3s ease;
  overflow: hidden;
  img {
    display: flex;
    align-items: flex-start;
    width: 6.4vw;
    height: 6.4vw;
  }
  p {
    color: #818181;
    display: block;
    font-size: 4.8vw;
    font-weight: 700;
    text-decoration: none;
    transition: 0.3s;
    margin-top: 13.333vw;
  }
}

.sidenav {
  height: unset;
  transition: right 2s ease;
  overflow: hidden;
  .d-flex {
    display: flex;
    .close-btn {
      display: flex;
      align-items: center;
      .close-icon {
        width: 10.667vw;
        height: 10.667vw;
      }
    }
    .brandLogo1 {
      display: flex;
      width: 42.267vw !important;
      height: 10.667vw !important;
      justify-content: center;
    }
    .margin-67 {
      margin-left: 17.867vw !important;
    }
  }
  .accordian-bg {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    .accordion {
      --bs-accordion-color: black;
      --bs-accordion-btn-color: black;
      --bs-accordion-btn-icon: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%23000000"%3e%3cpath fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/%3e%3c/svg%3e');
      --bs-accordion-active-bg: white;
      --bs-accordion-active-color: black;
      --bs-accordion-btn-active-icon: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%23000000"%3e%3cpath fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/%3e%3c/svg%3e');
      --bs-accordion-btn-focus-box-shadow: none;
      margin-top: 4.831vw;
      width: 100%;
      .accordion-item {
        border: none;
        padding-top: 1.932vw;
        padding-bottom: 1.932vw;
        .accordion-header {
          border-bottom: 1.5px rgba(220, 220, 240, 0.1) solid;
          padding-top: 1.932vw;
          padding-bottom: 1.932vw;
          .accordion-button {
            width: 53.333vw;
            padding: 2vw 2.797vw;
            font-size: 3.865vw;
            font-weight: 600;
            border-radius: 5px;
          }
          .accordion-button::after {
            width: 5.25vw;
            height: 4.25vw;
            margin-left: 0;
            background-size: 4.25vw;
          }
          .accordion-button:focus {
            border-color: none;
          }
          .accordion-button:not(.collapsed) {
            color: var(--bs-accordion-active-color);
            background-color: var(--bs-accordion-active-bg);
            box-shadow: none;
          }
        }
        .accordion-body {
          padding: 2.797vw 5.797vw;
          p {
            text-align: left;
            width: max-content;
            font-weight: 400 !important;
            font-size: 3.465vw !important;
            color: var(--bs-heading-color);
            display: block;
            font-size: 4.8vw;
            font-weight: 700;
            text-decoration: none;
            transition: 0.3s;
            margin-top: 0;
          }
        }
      }
    }
    .accordian5 {
      align-self: stretch;
      height: auto;
      padding-left: 5.797vw;
      padding-right: 5.797vw;
      padding-top: 1.932vw;
      padding-bottom: 1.932vw;
      border-bottom: 1.5px rgba(220.05, 220.05, 240, 0.1) solid;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 5.797vw;
      display: flex;
      padding: 5.797vw 5.797vw 3.865vw 5.797vw;
      .call-us {
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.966vw;
        display: inline-flex;
        .call-us1 {
          justify-content: flex-start;
          align-items: center;
          gap: 2.415vw;
          display: inline-flex;
          .call-us2 {
            color: black;
            font-size: 3.865vw;
            font-weight: 600;
            line-height: 4.947vw;
            word-wrap: break-word;
          }
        }
        .d-flex {
          display: flex;
        }
        .pt-16 {
          padding-top: 3.865vw;
          gap: 5.797vw;
          svg {
            width: 3.865vw;
            height: 3.865vw;
          }
        }
      }
    }
    .copyright-content1 {
      width: 100%;
      height: auto;
      text-align: left;
      word-wrap: break-word;
      font-size: 3.382vw;
      color: #6e6e73;
      padding-bottom: 72.464vw;
    }
    .p-24 {
      padding: 5.797vw;
    }
  }
  &.open {
    left: 0;
    animation: slideIn 2s ease;
    height: 100vh;
    overflow-y: scroll;
  }
  &.close {
    right: -800px;
    animation: slideOut 2s ease;
  }
}
