.footer-main-sec {
  padding: 4.444vw 7.778vw 3.333vw;
  background-color: #f1f4f9;
  gap: 9.306vw;
  display: flex;
  margin-top: 50px;
  .left-sec {
    gap: 2.222vw;
    display: flex;
    flex-direction: column;
    width: 22.222vw;
    img {
      width: 11.014vw;
      height: 2.778vw;
    }
    .text {
      color: #71717a;
      font-size: 1.111vw;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      line-height: 1.806vw;
      word-wrap: break-word;
    }
  }
  .right-sec {
    display: flex;
    gap: 2.222vw;
    .headings {
      gap: 1.111vw;
      display: flex;
      flex-direction: column;
      width: 16.667vw;
      .head {
        color: #262626;
        font-size: 0.972vw;
        font-family: "Inter", sans-serif;
        font-weight: 600;
        line-height: 1.389vw;
        word-wrap: break-word;
      }
      .sub-head {
        color: black;
        font-size: 1.111vw;
        font-family: "Inter", sans-serif;
        font-weight: 500;
        line-height: 1.667vw;
        word-wrap: break-word;
        cursor: pointer;
        text-decoration: none;
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      gap: 0.753vw;
      img {
        cursor: pointer;
        width: 12.292vw;
        height: 3.882vw;
      }
    }
  }
}
.link-sec {
  padding: 3.333vw 7.778vw;
  display: flex;
  justify-content: space-between;
  .text {
    color: #1c3879;
    font-size: 1.111vw;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 1.667vw;
    word-wrap: break-word;
  }
  .social-icons {
    gap: 1.667vw;
    display: flex;
    img {
      cursor: pointer;
      width: 1.667vw;
      height: 1.667vw;
    }
  }
}

@media screen and (max-width: 600px) {
  .footer-main-sec {
    padding: 12.8vw 4.267vw;
    display: block;
    .left-sec {
      gap: 6.4vw;
      width: 100%;
      img {
        width: 42.293vw;
        height: 10.667vw;
      }
      .text {
        font-size: 4.267vw;
        line-height: 6.933vw;
        margin-bottom: 12.8vw;
      }
    }
    .right-sec {
      gap: 7vw;
      display: grid;
      grid-template-columns: 1fr 1fr;
      .headings {
        gap: 3.2vw;
        width: 41.253vw;
        .head {
          font-size: 3.733vw;
          line-height: 5.333vw;
        }
        .sub-head {
          font-size: 4.267vw;
          line-height: 6.4vw;
        }
      }
      .buttons {
        gap: 2.891vw;
        img {
          width: 47.2vw;
          height: 14.907vw;
        }
      }
    }
  }
  .link-sec {
    padding: 10.667vw 3.733vw;
    flex-direction: column-reverse;
    gap: 6.4vw;
    .text {
      font-size: 4.267vw;
      line-height: 6.4vw;
    }
    .social-icons {
      gap: 6.4vw;
      img {
        width: 6.4vw;
        height: 6.4vw;
      }
    }
  }
}
